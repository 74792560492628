<template>
  <div>
    <p class="red--text m-0">* Hãy chọn 1 đáp án đúng</p>
    <v-radio-group v-model="correctAnswer">
      <template v-for="(value, i) in optionAnswer">
        <div class="d-flex" :key="i">
          <p class="text-h5 mt-4 mr-2" style="color: #6495ed">
            {{ i | getAlphabetCharacterFromIndex }}.
          </p>
          <v-radio :label="value" :value="value" class="mb"></v-radio>
        </div>
      </template>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "YesNo",
  props: {
    option: {
      type: Array,
      default: () => [],
    },
    correct: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    optionAnswer: {
      get() {
        return this.option;
      },
    },
    correctAnswer: {
      get() {
        return this.correct;
      },
      set(value) {
        this.$emit("setCorrectAnswer", value);
      },
    },
  },
};
</script>

<style scoped></style>
